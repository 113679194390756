import React, { ChangeEvent, FocusEvent, useEffect, useState } from "react";

import { Input, InputProps } from "antd";
import cn from "classnames";

import { InputWrapper, InputWrapperProps } from "components/InputWrapper";

import styles from "./TextInput.module.scss";

type Props = {
  wrapperClassName?: string;
  onTextChange: (value: string) => void;
  formatNumber?: boolean;
} & InputWrapperProps &
  InputProps;

const formatNumberDisplay = (value: string) => {
  if (!value) return value;
  const cleanedValue = value.replace(/\,/g, "");
  return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const TextInput = ({
  label,
  optional,
  tooltip,
  error,
  wrapperClassName,
  className,
  onTextChange,
  onChange,
  placeholder = "Type here",
  onBlur,
  formatNumber = false,
  value,
  ...rest
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(value?.toString() || "");

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setInputValue(value.toString());
    }
  }, [value]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/\,/g, "");

    if (formatNumber) {
      const parts = cleanedValue.split(".");
      if (parts.length > 1 && parts[1].length > 2) {
        return;
      }
    }

    setIsActive(true);
    setInputValue(cleanedValue);
    onTextChange(cleanedValue);
    onChange?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsActive(false);
    onBlur?.(e);
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if (rest.type === "number") {
      event.currentTarget.blur();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (formatNumber && rest.type === "number") {
      const key = event.key;
      if (!/[0-9]/.test(key) && key !== ".") {
        event.preventDefault();
      }
    }
  };

  const displayValue = formatNumber ? formatNumberDisplay(inputValue) : inputValue;

  return (
    <InputWrapper
      label={label}
      optional={optional}
      error={!isActive ? error : undefined}
      tooltip={tooltip}
      className={wrapperClassName}
    >
      <Input
        className={cn(styles.textInput, className)}
        value={displayValue}
        onChange={handleTextChange}
        placeholder={placeholder}
        bordered={false}
        onBlur={handleBlur}
        onWheel={handleWheel}
        autoComplete={"off"}
        {...rest}
        onKeyPress={handleKeyPress}
        type={formatNumber ? "text" : rest.type}
      />
    </InputWrapper>
  );
};
