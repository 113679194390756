import React from "react";

import { ReactComponent as DangerSVG } from "./icons/danger.svg";

import styles from "./RiskBanner.module.scss";

export const RiskBanner: React.FC = () => {
  if (process.env.REACT_APP_UKFINPROM_ENABLED?.toLowerCase() !== "true") {
    return null;
  }

  return (
    <div className={styles.riskBanner}>
      <DangerSVG />
      <span>
        Don&apos;t invest unless you&apos;re prepared to lose all the money you invest. This is a high-risk investment
        and you should not expect to be protected if something goes wrong. Take 2 mins to{" "}
        <a href='https://bnktothefuture.com/uk-risk-disclaimer' target='_blank' rel='noreferrer'>
          learn more.
        </a>
      </span>
    </div>
  );
};
