import { isNil } from "ramda";

import { getVirtualAssetsData } from "api/virtualAssets";
import { useUser } from "hooks";

import { useQueryData } from "./useQueryData";

export const useVirtualAssetsData = (statusRejected?: boolean) => {
  const { data } = useUser();

  return useQueryData(["virtualAssetsData", data], () => (!isNil(data) ? getVirtualAssetsData(data.type) : undefined), {
    enabled: statusRejected !== false && !isNil(data),
  });
};
