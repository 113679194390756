import { map, prop } from "ramda";

import { ProfileType } from "types/account";
import { ProcessData } from "types/process";
import { AdapterMapper } from "types/utils";
import { VirtualAssetsInd, VirtualAssetsOrg } from "types/virtualAssets";

class VirtualAssetsAdapterClass {
  commonMapper = {
    status: prop("status"),
    currentStep: prop("currentStep"),
    rejectionReasons: prop("rejectionReasons"),
    cryptoTrading: prop("cryptoTrading"),
    cryptoInvestmentLevel: prop("cryptoInvestmentLevel"),
    cryptoInvestmentFrequency: prop("cryptoInvestmentFrequency"),
    sharesTrading: prop("sharesTrading"),
    sharesInvestmentLevel: prop("sharesInvestmentLevel"),
    sharesInvestmentFrequency: prop("sharesInvestmentFrequency"),
    sourcesOfWealthDetails: prop("sourcesOfWealthDetails"),
    sourcesOfFundsDetails: prop("sourcesOfFundsDetails"),
  } as const;

  individualMapper = {
    ...this.commonMapper,
    sourcesOfWealth: prop("sourcesOfWealth"),
    sourcesOfFunds: prop("sourcesOfFunds"),
    netWorth: prop("netWorth"),
    annualIncome: prop("annualIncome"),
    taxId: prop("taxId"),
    phone: prop("phone"),
    phoneCountry: prop("phoneCountry"),
    phonePrefix: prop("phonePrefix"),
    employmentStatus: prop("employmentStatus"),
    occupation: prop("occupation"),
    businessName: prop("businessName"),
    businessSector: prop("businessSector"),
  } as const;

  organizationalMapper = {
    ...this.commonMapper,
    sourcesOfWealth: prop("sourcesOfWealth"),
    sourcesOfFunds: prop("sourcesOfFunds"),
    lastAccountsNetAsset: prop("lastAccountsNetAsset"),
    lastAccountsNetProfit: prop("lastAccountsNetProfit"),
  } as const;

  takeOutData = (type: ProfileType, item: unknown) => {
    switch (type) {
      case "individual":
        return map<AdapterMapper<unknown>, VirtualAssetsInd>((fn) => fn(item), this.individualMapper);
      case "organizational":
        return map<AdapterMapper<unknown>, VirtualAssetsOrg>((fn) => fn(item), this.organizationalMapper);
    }
  };

  toModel<T extends ProcessData<unknown>>(item: T, type: ProfileType) {
    return {
      last: this.takeOutData(type, item.last),
      current: this.takeOutData(type, item.current),
    };
  }
}

export const VirtualAssetsAdapter = new VirtualAssetsAdapterClass();
