import { map, prop } from "ramda";

import { FullCertification } from "types/fullCertification";
import { ProcessData } from "types/process";
import { AdapterMapper } from "types/utils";

class FullCertificationAdapterClass {
  receivingMapper = {
    status: prop("status"),
    currentStep: prop("currentStep"),
    rejectionReasons: prop("rejectionReasons"),
    documents: prop("documents"),
    type: prop("type"),
    regulatedInstitutionDetails: prop("regulatedInstitutionDetails"),
    proofOfAddressType: prop("proofOfAddressType"),
    hasDocumentTranslation: prop("hasDocumentTranslation"),
  } as const;

  takeOutData = (item: unknown) => {
    return map<AdapterMapper<unknown>, FullCertification>((fn) => fn(item), this.receivingMapper);
  };

  toModel<T extends ProcessData<unknown>>(item: T) {
    return {
      last: this.takeOutData(item.last),
      current: this.takeOutData(item.current),
    };
  }
}

export const FullCertificationAdapter = new FullCertificationAdapterClass();
