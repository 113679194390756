import { map, prop } from "ramda";

import { IProcessStateReport } from "types/processStateReport";
import { AdapterMapper } from "types/utils";

class ProcessStatusReportClass {
  receivingMapper = {
    currentStatus: prop("currentStatus"),
    expiresAt: prop("expiresAt"),
    lastStatus: prop("lastStatus"),
    previousStatus: prop("previousStatus"),
    rejectionReasons: prop("rejectionReasons"),
    startable: prop("startable"),
    retriableAt: prop("retriableAt"),
    activatedAt: prop("activatedAt"),
  } as const;

  toModel<T = unknown>(data: T) {
    return map<AdapterMapper<T>, IProcessStateReport>((fn) => fn(data), this.receivingMapper);
  }
}

export const ProcessStatusReport = new ProcessStatusReportClass();
