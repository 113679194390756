import { map, prop } from "ramda";

import { ProfileType } from "types/account";
import { ProcessData } from "types/process";
import { SelfCertificationInd, SelfCertificationOrg } from "types/selfCertification";
import { AdapterMapper } from "types/utils";

class SelfCertificationAdapterClass {
  commonMapper = {
    status: prop("status"),
    currentStep: prop("currentStep"),
    rejectionReasons: prop("rejectionReasons"),
    investmentHorizon: prop("investmentHorizon"),
    totalAssets: prop("totalAssets"),
    totalLiquidAssets: prop("totalLiquidAssets"),
    isLiquidAssetSufficient: prop("isLiquidAssetSufficient"),
    quiz: prop("quiz"),
  } as const;

  individualMapper = {
    ...this.commonMapper,
    qualificationCriteria: prop("qualificationCriteria"),
    typesOfService: prop("typesOfService"),
    typesOfServiceDetails: prop("typesOfServiceDetails"),
  } as const;

  organizationalMapper = {
    ...this.commonMapper,
    publiclyTradedShares: prop("publiclyTradedShares"),
  } as const;

  takeOutData = (type: ProfileType, item: unknown) => {
    switch (type) {
      case "individual":
        return map<AdapterMapper<unknown>, SelfCertificationInd>((fn) => fn(item), this.individualMapper);
      case "organizational":
        return map<AdapterMapper<unknown>, SelfCertificationOrg>((fn) => fn(item), this.organizationalMapper);
    }
  };

  toModel<T extends ProcessData<unknown>>(item: T, type: ProfileType) {
    return {
      current: this.takeOutData(type, item.current),
      last: this.takeOutData(type, item.last),
    };
  }
}

export const SelfCertificationAdapter = new SelfCertificationAdapterClass();
