import { append, assocPath, find, findIndex, isNil, propEq, remove } from "ramda";
import { useRecoilState } from "recoil";

import { AlertAtomProps, alertsAtom } from "atomsStorage/alerts";
import { Alert } from "components/Alert";

export const useAlerts = () => {
  const [alerts, setAlerts] = useRecoilState(alertsAtom);

  const addAlert = (id: string, Element: (props: any) => JSX.Element = Alert, props?: AlertAtomProps) => {
    if (isNil(Element) && isNil(props)) return;

    setAlerts((prev) => {
      const inList = find(propEq("id", id), prev);
      if (inList) return prev;
      return append({ id, isVisible: true, Element, props }, prev);
    });
  };

  const removeAlert = (id: string) => {
    setAlerts((prev) => {
      const index = findIndex(propEq("id", id), prev);
      if (index === -1) return prev;
      return remove(index, 1, prev);
    });
  };

  const closeAlert = (id: string) => {
    setAlerts((prev) => {
      const index = findIndex(propEq("id", id), prev);
      if (index === -1) return prev;
      return assocPath([index, "isVisible"], false, prev);
    });
  };

  return { alerts, addAlert, closeAlert, removeAlert };
};
