import React, { useState } from "react";

import { insert } from "ramda";
import { useNavigate } from "react-router-dom";

import { Alert } from "components/Alert";
import { BackButton } from "components/BackButton";
import { Button } from "components/Button";
import { InputWrapper } from "components/InputWrapper";
import { SelectionIndicator } from "components/SelectionIndicator";
import { SubmitButton } from "components/SubmitButton";
import { SummarySection } from "components/SummarySection";
import { BOOLEAN_ANSWER } from "constants/formData";
import { HOME_PATH } from "constants/pages";
import { useKycData, useMutateKyc, useStepsProgress, useSubmit } from "hooks";
import { usePoaStepsProgress } from "hooks/usePoaStepsProgress";
import { KycInd } from "types/kyc";
import { getCountryByCode } from "utils/formData";

import { appsignal } from "../../../../appsignal";
import { useCountriesContext } from "../../../../contexts/countriesCtx";
import { isSanctionedCountryQuestionsRequired } from "../helpers";

import styles from "./KycSummary.module.scss";

export const KycSummary = () => {
  const [confirmedData, setConfirmedData] = useState(false);
  const { countriesOptions = [] } = useCountriesContext();
  const [submitError, setSubmitError] = useState("");
  const { goToPreviousScreen, setScreen } = useStepsProgress();
  const { setUploadDocsCurrentScreen } = usePoaStepsProgress();
  const { data } = useKycData();
  const updateKyc = useMutateKyc();
  const submitKyc = useSubmit("kyc");
  const navigate = useNavigate();
  if (!data) return null;

  const currentData = data.last as KycInd;

  const poiData = [
    { label: "name", value: currentData.name },
    { label: "date of birth", value: currentData.birthday },
    {
      label: "documents",
      value: currentData.documents.filter(({ type }) => type === "proof_of_identity_document"),
    },
  ];

  const poaData = [
    { label: "country", value: getCountryByCode(currentData.addressCountry, countriesOptions) },
    { label: "address", value: currentData.addressLine1 },
    { label: "city", value: currentData.city },
    { label: "state", value: currentData.state },
    { label: "poscode/zip", value: currentData.postcode },
    {
      label: "place of birth",
      value: getCountryByCode(currentData.placeOfBirth, countriesOptions),
    },
    {
      label: "nationality",
      value: getCountryByCode(currentData.nationality, countriesOptions),
    },
    { label: "another nationality", value: currentData.secondaryNationalities?.[0].has || "No" },
    {
      label: "documents",
      value: currentData.documents.filter(({ type }) => type === "proof_of_address_en" || type === "proof_of_address"),
    },
  ];

  const scqData = Object.values(currentData.sanctionedCountryQuestions)
    .reduce((questions, questionsForCountry) => [...questions, ...questionsForCountry], [])
    .map(({ question, answer }) => ({ label: question, value: answer || "none", componentType: "radio" as const }));

  if (currentData.addressLine2) {
    insert(2, { label: "address", value: currentData.addressLine2 }, poaData);
  }

  const handleSubmit = async () => {
    setSubmitError("");
    await updateKyc.mutateAsync({ confirmDataValidity: confirmedData });
    submitKyc
      .mutateAsync()
      .then(() => {
        navigate(HOME_PATH);
      })
      .catch((err) => {
        setSubmitError(err.response.data.message || "Something went wrong");
        appsignal.sendError(err);
      });
  };

  return (
    <div className={styles.kycSummary}>
      <h2 data-cy='summary-title'>Summary</h2>
      <Alert
        description={
          <>
            If any of your Identity data is incorrect, please contact{" "}
            <a href={process.env.REACT_APP_CORE_SUPPORT} rel='noreferrer' target='_blank'>
              BF Support
            </a>
          </>
        }
        type='warning'
        className={styles.alert}
        greyOut={true}
      />
      <SummarySection header='Proof of Identity' data={poiData} />
      <SummarySection
        header='Proof of Address'
        headerButton='Edit'
        handleButtonClick={() => {
          setScreen(3);
        }}
        data={poaData}
        spaceSize={20}
      />
      {isSanctionedCountryQuestionsRequired([currentData.addressCountry, currentData.nationality]) && (
        <SummarySection
          header='Sanctioned Countries Questions'
          headerButton='Edit'
          handleButtonClick={() => {
            setScreen(4);
          }}
          data={scqData}
        />
      )}
      <InputWrapper error={submitError} className={styles.wrapper}>
        <SelectionIndicator
          dataCy='privacy-policy-checkbox'
          type='checkbox'
          checked={confirmedData}
          onClick={() => {
            setConfirmedData((prev) => !prev);
          }}
          label={
            <span>
              I hereby confirm that the data given is true and give BnkToTheFuture (BF) my consent to process my data
              according to the{" "}
              <Button type='link' href={`${process.env.REACT_APP_CORE_WORDPRESS}/privacy-cookies`}>
                Privacy Policy
              </Button>
              .
            </span>
          }
          className={styles.confirmCheckbox}
        />
      </InputWrapper>
      <SubmitButton
        onClick={handleSubmit}
        disabled={!confirmedData}
        loading={submitKyc.isLoading || updateKyc.isLoading}
      />
      <BackButton
        onClick={() => {
          updateKyc.mutateAsync({ currentStep: 6 }).then(() => {
            goToPreviousScreen(() => {
              setUploadDocsCurrentScreen(data.last.hasDocumentTranslation === BOOLEAN_ANSWER.no ? 1 : 2);
            });
          });
        }}
      />
    </div>
  );
};
