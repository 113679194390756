import { isNil } from "ramda";

import { getFullCertificationData } from "api/fullCertification";

import { useQueryData } from "./useQueryData";
import { useUser } from "./useUser";

export const useFullCertificationData = (statusRejected?: boolean) => {
  const { data } = useUser();
  return useQueryData(
    ["fullCertification", data],
    () => (!isNil(data) ? getFullCertificationData(data.type) : undefined),
    {
      enabled: statusRejected !== false && !isNil(data),
    }
  );
};
