import { map, prop } from "ramda";

import { AdapterMapper } from "types/utils";

class VeriffAdapterClass {
  receivingMapper = {
    url: prop("redirect_url"),
  } as const;

  toModel<T = unknown>(item: T) {
    return map<AdapterMapper<T>, { url: string }>((fn) => fn(item), this.receivingMapper);
  }
}
export const VeriffAdapter = new VeriffAdapterClass();
