import React, { Fragment, useState } from "react";

import { notification } from "antd";
import { Badge, Space, SpaceProps, Typography } from "antd";

import { DocumentJpgSVG, DocumentPdfSVG } from "assets/icons";
import { Button } from "components/Button";
import { Divider } from "components/Divider";
import Lightbox from "components/Lightbox/Lightbox";
import { Question } from "components/Question";
import { DocumentFile } from "types/formData";
import { ApiService } from "utils/requestService";

import { appsignal } from "../../appsignal";

import styles from "./SummarySection.module.scss";

export type SummarySectionProps = {
  header: string;
  headerButton?: string;
  handleButtonClick?: () => void;
  spaceSize?: SpaceProps["size"];
  sectionErrorCount?: number;
  data: { label: string; value: string | DocumentFile[]; componentType?: "checkbox" | "radio" }[];
};

export const SummarySection = ({
  header,
  headerButton,
  sectionErrorCount = 0,
  handleButtonClick,
  data,
  spaceSize = 20,
}: SummarySectionProps) => {
  const dataLength = data.length;
  const [previewFileUrl, setPreviewFileUrl] = useState<string | undefined>();

  const handleOnFileClick = ({ fileUrl, isPDF }: { fileUrl: string; isPDF: boolean }) => {
    ApiService.getFilePreview(fileUrl)
      .then((responseURL) => {
        if (isPDF) {
          window.open(responseURL, "_blank", "noreferrer");
        } else {
          setPreviewFileUrl(responseURL);
        }
      })
      .catch((err) => {
        notification.error({ message: err.response?.statusText || "Something went wrong" });
        appsignal.sendError(err);
      });
  };

  const renderDocuments = (files: DocumentFile[]) => {
    return (
      <div className={styles.documentsList}>
        {files.map((file) => {
          const isPDF = file.name.endsWith(".pdf");
          const icon = isPDF ? <DocumentPdfSVG /> : <DocumentJpgSVG />;
          return (
            <>
              <div key={`file-${file.id}`} className={styles.document}>
                {icon}
                <span>{file.name}</span>
              </div>
            </>
          );
        })}
        <Lightbox
          open={!!previewFileUrl}
          index={0}
          close={() => setPreviewFileUrl(undefined)}
          slides={[{ src: previewFileUrl ?? "" }]}
        />
      </div>
    );
  };

  const renderQuestionAnswers = (value: string, answerType: "checkbox" | "radio") => {
    const answers = answerType === "checkbox" ? (value as string)?.split(",") : [value];

    return (
      <div style={{ filter: "grayscale()" }}>
        <Question
          options={answers?.map((v) => ({ option: v?.replace(/\_/g, " "), value: v }))}
          value={answers}
          onClick={() => undefined}
          disabled={true}
          answerType={answerType}
        />
      </div>
    );
  };

  const renderDataItem = (item: (typeof data)[number], index: number) => {
    return (
      <Fragment key={item.label}>
        <div className={styles.summaryItem}>
          <div className={styles.summaryItemHeader}>{item.label}</div>
          <div className={styles.summaryItemValue}>
            {item.componentType && ["checkbox", "radio"].includes(item.componentType)
              ? renderQuestionAnswers(item.value as string, item.componentType)
              : item.label === "documents"
              ? renderDocuments(item.value as DocumentFile[])
              : (item.value as string)}
          </div>
        </div>
        {index < dataLength - 1 ? <Divider className={styles.divider} /> : null}
      </Fragment>
    );
  };

  const errorLabel = sectionErrorCount === 1 ? "error" : "errors";

  return (
    <div className={styles.summary}>
      <div className={styles.summaryHeader}>
        <h3>{header}</h3>
        {headerButton ? (
          <Space size={[20, 20]}>
            <Badge count={sectionErrorCount ? "!" : 0}>
              <Button type='outlineGrey' onClick={handleButtonClick} sm>
                {headerButton}
              </Button>
            </Badge>
            {sectionErrorCount !== 0 && (
              <Typography.Text type='danger'>
                {sectionErrorCount} validation {errorLabel}
              </Typography.Text>
            )}
          </Space>
        ) : null}
      </div>
      <Space direction='vertical' size={spaceSize} className={styles.summaryData}>
        {data.map(renderDataItem)}
      </Space>
    </div>
  );
};
